require('slick-carousel');
require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');

const $ = require('jquery');
window.jQuery = $;

$( document ).ready(function() {

    $('.welcome-screen-slider').slick({
        infinite: true,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });

    $("#povlja-section").click(function() {
        $('html, body').animate({
            scrollTop: $("#povlja").offset().top
        }, 1200);
    });

});